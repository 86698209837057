<template>
  <page-title-component title="Team"></page-title-component>

  <div class="row">
    <div class="col-md-12 layout-spacing">
      <div class="widget portlet-widget">
        <div class="widget-content widget-content-area">
          <div class="portlet portlet-basic">
            <div class="portlet-title">
              <div class="p-caption">
                <div class="row">
                  <div class="col-6">
                    <span class="caption-subject text-uppercase">Team Members</span>
                  </div>
                  <div class="col-6 text-right">
                    <a href="#"
                       @click.prevent="$refs.inviteModal.show()"
                       class="btn btn-sm btn-primary">Invite</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="portlet-body py-4">
              <p>
                <b>Permissions legend:</b> <br>
                Viewer: Can only see dashboard<br>
                Editor: Same as Viewer but can also create/link campaigns<br>
                Manager: Same as Editor but can also give permissions to others<br>
                Owner: Can do anything. The only one who can change passwords and connect accounts
              </p>
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th class="text-right">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="account in accounts"
                    :key="'account_'+account.id">
                  <td>{{ account.user.name }}</td>
                  <td>{{ account.user.email }}</td>
                  <td>
                    <span v-if="account.role === 1">Owner</span>
                    <span v-if="account.role === 2">Manager</span>
                    <span v-if="account.role === 3">Viewer</span>
                    <span v-if="account.role === 4">Editor</span>
                  </td>
                  <td class="text-right">
                    <button class="btn btn-sm btn-warning mr-1"
                            v-if="account.role !== 1"
                            @click="$refs.editRoleModal.show(account)">
                      <i class="fas fa-edit"></i>
                    </button>
                    <button class="btn btn-sm btn-danger"
                            @click="removeMember(account)"
                            v-if="account.role !== 1">
                      <i class="fas fa-trash"></i>
                    </button>
                  </td>
                </tr>
                <tr v-for="invitation in invitations"
                    :key="'invitation_'+invitation.id">
                  <td>
                    {{ invitation.to }}
                    <span class="font-weight-bold text-info">(Invited)</span>
                  </td>
                  <td>
                    <span v-if="invitation.role === 1">Owner</span>
                    <span v-if="invitation.role === 2">Manager</span>
                    <span v-if="invitation.role === 3">Viewer</span>
                    <span v-if="invitation.role === 4">Editor</span>
                  </td>
                  <td></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <invite-modal @invited="loadInvitations" ref="inviteModal" />
  <edit-role-modal @updated="loadAccounts" ref="editRoleModal" />
</template>

<script>
import PageTitleComponent from "@/components/PageTitleComponent";
import {mapGetters} from "vuex";
import InviteModal from "@/views/modals/InviteModal.vue";
import EditRoleModal from "@/views/team/EditRoleModal.vue";

export default {
  components: {PageTitleComponent, InviteModal, EditRoleModal},
  data() {
    return {
      invitations: [],
      accounts: []
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
    })
  },
  created() {
    this.loadAccounts();
    this.loadInvitations();
  },
  methods: {
    loadAccounts() {
      this.accounts = [];

      this.axios.get('/members')
        .then(res => {
          this.accounts = res.data.data;
        })
    },
    loadInvitations() {
      this.axios.get('/invitations')
        .then(res => {
          this.invitations = res.data.data;
        })
    },
    removeMember(account) {
      this.$swal({
        title: 'Are you sure want to remove?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgba(253,23,31,0.7)',
        confirmButtonText: 'Yes!',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete('/members/' + account.id)
            .then(() => {
              this.loadAccounts();
            });
        }
      }).finally(() => this.modalLoading = false);
    },
  }
}
</script>