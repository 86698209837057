<template>
  <div class="modal fade"
       id="modal-edit-role"
       tabindex="-1"
       role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-add-cardLabel">Edit Role</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label>Role</label>
            <select :class="['form-control', {'is-invalid': errorFor('role')}]"
                    :disabled="loading"
                    v-model="form.role">
              <option value="">Select Role</option>
              <option value="2">Manager</option>
              <option value="4">Editor</option>
              <option value="3">Viewer</option>
            </select>
            <v-errors :errors="errorFor('role')" />
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary"
                  :disabled="loading"
                  @click="submit">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      accountUser: '',
      form: {
        role: ''
      }
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('modal-edit-role'))
  },
  methods: {
    show(accountUser) {
      this.accountUser = accountUser;
      this.form.role = accountUser.role;

      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    submit() {
      this.allErrors = null;
      this.loading = true;

      this.axios.post('/members/role/' + this.accountUser.id, this.form)
        .then(() => {
          this.hide();
          this.$emit('updated')
        })
        .catch((err) => {
          this.allErrors = err.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>